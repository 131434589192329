// редирект методами js (на сайт партнера)
function redirect(url){
    setTimeout(()=>{
        document.location.href = url
    }, 500)
    
}

//http://127.0.0.1:3000/teasers/103

function createTimeout(url, time = 60, callback){
    // time = localStorage.getItem('redirectTime') || 60;
    // localStorage.setItem('redirectTime', 60);
    // console.log(time)
    return setTimeout(()=>{
            callback()
            redirect(url)
        }, time * 1000)

}

function initRedirect(time, callback){
    // console.log('reditect timer')
    // выбираем главный тизер на транзитке
    const targetTeaser = document.querySelector('[data-targetUrl]')
 
    if (targetTeaser) {
        // console.log('init redirect', time)
        const url = targetTeaser.attributes.href.value; // урл партнера главного тизера (куда редиректим)
        // урл учета перехода (локальный сбор статистики по кликам)
        const localMetricFetch = targetTeaser.dataset.staturl + '&url=' + document.location.pathname;
        let timer = createTimeout(url, time, ()=>{
            fetch(localMetricFetch) // собираем статистику перехода
            callback() // checkClicks() из application.js: initRedirect(redirectTime, checkClicks)
        })
        
        window.addEventListener('scroll', ()=>{
            clearTimeout(timer)
            timer = createTimeout(url, time, ()=>{
                fetch(localMetricFetch)
                callback()
            })
        })

        targetTeaser.addEventListener('click', ()=>{
            // console.log('stop timer');
            clearTimeout(timer)
        })   
    }
}



export {
    initRedirect
}