// отсылка событий просмотра тизера при его попадании в зону видимости (у нас на сайте)
// TODO: пакетная отсылка событий попадания тизеров в область видимости (при загрузке стр отправляется очень много запросов)
// TODO: считать ли повторные просмотры тизеров тем же пользователем?
function handleIntersection(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting && !entry.target.classList.contains('t-viewed')) {
      entry.target.classList.add('t-viewed');
      fetch('/external/s-teaser-view?tid=' + entry.target.dataset.tid + '&type=' + entry.target.dataset.type + '&pos=' + entry.target.dataset.pos);
    }
  });
}


// TODO: отслеживать события для подгружаемых тизеров
function checkTeasersViewed() {
  var elements = document.querySelectorAll(".ym-link");

  const options = {
    root: null, // Используем viewport как корневой элемент
    threshold: 1, // Задаем порог видимости (1 - 100%)
  };

  const observer = new IntersectionObserver(handleIntersection, options);

  // elements.forEach((el) => {
  //   observer.observe(el);
  // });
  elements.forEach(el => {
    observer.observe(el)
  });
}


export {
  checkTeasersViewed
}