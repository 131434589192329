function getCookie(key) {
  let cookies = document.cookie.split('; ');
  let l = cookies.length;
  for (let i = 0; i < l; i++) {
    let parts = cookies[i].split('=');
    if (parts.shift() === key) {
      const cookieValue = decodeURIComponent(parts.join('='));
      return cookieValue;
    }
  }
  return undefined;
}

function setCookie(key, value, options) {
  let optionsString = ''
  for (let key in options) {
    optionsString = optionsString + key + '=' + options[key] + '; '
  }
  document.cookie = `${key}=${value}; ${options}`
}

export {setCookie, getCookie}
