export default function PrevNextSlide(){
    const next = document.querySelector(".js-next-slide")
    const prev = document.querySelector(".js-prev-slide")
    const container = document.querySelector(".js-slide-container")

    
    if (container && next && prev){
        const offsetWidth = container.clientWidth


        container.onscroll = ()=>{
            checkScroll(container, next, prev)
        }

        next.onclick = ()=>{
            container.scrollLeft = container.scrollLeft + offsetWidth
        }
        prev.onclick = ()=>{
            container.scrollLeft = container.scrollLeft - offsetWidth
        }
        checkScroll(container, next, prev)
    }
}


function checkScroll(container, next, prev){
    if(container.scrollLeft === 0) {        
        prev.style.display = 'none'
        next.style.display = 'flex'
    }else if (container.scrollWidth - container.scrollLeft - 5 <= container.clientWidth){
        prev.style.display = 'flex'
        next.style.display = 'none'
    }else{
        prev.style.display = 'flex'
        next.style.display = 'flex'
    }


}
