export default function MoreNews() {
    return moreNews()
}
export { moreTeasers, autoloadsCheck, articlesInfinityScroll, checkArticleUrl, clickNextArticle }


function moreNews() {
    const btn = document.querySelector('.js-mobile-more')
    const rem = document.querySelector("body");
    if (!btn) return
    let loading = false
    window.addEventListener('scroll', () => {
        if (!loading && window.scrollY > rem.scrollHeight - window.innerHeight - 100) {
            loading = true
            const tempBtn = document.querySelector('.js-mobile-more');
            tempBtn.click()
        } else if (window.scrollY <= rem.scrollHeight - window.innerHeight - 100) {
            loading = false
        }
    })
}

function moreTeasers() {
    const btn = document.querySelector('.js-more-news')
    const rem = document.querySelector("body");
    if (!btn) return
    let loading = false
    window.addEventListener('scroll', () => {
        if (!loading && window.scrollY > rem.scrollHeight - window.innerHeight - 500) {
            loading = true
            const tempBtn = document.querySelector('.js-more-news');
            tempBtn.click()
        } else if (window.scrollY <= rem.scrollHeight - window.innerHeight - 500) {
            loading = false
        }
    })
}

// /* Костыли потому что нет API, берем последние элементы после автоподгрузки*/
// function moreTeasers() {

//     let options = {
//         root: null,
//         rootMargin: '0px',
//         threshold: 1
//     }

//     let observer = new IntersectionObserver(getMoreTeasers, options);
//     const target = document.querySelectorAll('li.news-list__item:last-child');
//     const length = target.length
//     if (!length) return
//     observer.observe(target[length - 1]);
// }

// let loading = false
// function getMoreTeasers([entry], observer) {

//     if (entry.isIntersecting && !loading) {
//         loading = true

//         const nodeList = document.querySelectorAll(".js-more-news")
//         if (!nodeList.length) return
//         nodeList[nodeList.length - 1].click()
//         observer.unobserve(entry.target)
//         setTimeout(() => {
//             const target = document.querySelectorAll('li.news-list__item:last-child');
//             observer.observe(target[target.length - 1]);
//         }, 500)
//     } else {
//         loading = false
//     }
// }


// ------------- Бесконечный скролл -------------
// function autoloadsCheck(observeClassName) {
//   console.log(1)
//   let options = {
//     root: null,
//     rootMargin: '0px',
//     threshold: 1
//   }
//   let observer = new IntersectionObserver(getMoreTeasers(observeClassName), options);
//   const target = document.querySelector(observeClassName);
//   if (!target) return;
//   observer.observe(target);
//   console.log(2)
// }

// let loading = false
// function getMoreTeasers([entry], observer, observeClassName) {
//   console.log('class: ' + observeClassName);
//   if (entry.isIntersecting && !loading) {
//     loading = true
//     console.log(entry.target.href)
//     entry.target.click()
//     observer.unobserve(entry.target)
//     setTimeout(() => {
//       const target = document.querySelector(observeClassName);
//       if (target) {
//         console.log(111)
//         observer.observe(target);
//       }
//     }, 5000)
//   } else {
//     loading = false
//   }
// }

function autoloadsCheck(observeClassName) {
    // observeClassName - класс, за которым следим для автоподгрузки
    let loading = false;

    // Создаем новый экземпляр Intersection Observer
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        // console.log('loading: ' + loading)
        // console.log('isIntersecting: ' + entry.isIntersecting)
        // console.log('href: ' + entry.target.href)
        // Проверяем, если целевой элемент в зоне видимости и этот элемент не загружается в данный момент
        if (entry.isIntersecting && !loading) {
          loading = true; // Устанавливаем флаг загрузки
          observer.unobserve(entry.target) // отвязка обсервера
          entry.target.click(); // Выполняем загрузку новых данных (кликом по ajax-ссылке)
          // console.log('CLICK href: ' + entry.target.href)
          setTimeout(() => {
            // console.log('timeout start!')
            let newTarget = document.querySelector(observeClassName);
            if (newTarget) observer.observe(newTarget); // замыкание
            loading = false;
          }, 1000);
        } else {
          loading = false;
        }
      });
    }, {
      root: null,
      // Указываем порог, который говорит о том, сколько процентов элемент должен быть видимым
      threshold: 0.5, // Например, 0.5 означает, что элемент будет считаться видимым, если он виден на 50% или более
      // Указываем отступы для корневого элемента (viewport)
      rootMargin: '500px' // Установим отступ в 500px до достижения элемента
    });

    // Находим элемент с классом autoload-link
    const targetElement = document.querySelector(observeClassName);

    // Запускаем Intersection Observer для отслеживания этого элемента
    if (targetElement) observer.observe(targetElement);
}
// ------------- /Бесконечный скролл -------------


// ------------- Бесконечный скролл на странице статьи со сменой урла -------------
// ----------------------------- (ТЕСТОВАЯ ВЕРСИЯ) --------------------------------
function articlesInfinityScroll() {
  var loading = false;
  window.addEventListener('scroll', function() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 1000 && !loading) {
      // loading = clickNextArticle(loading);
    }
  });
}

// загрузка следующей статьи
function clickNextArticle(loading) {
  loading = true;
  var next_url_el = document.querySelector('.next-article-url')
  if (next_url_el) {
    next_url_el.click()
    // history.pushState(null, null, next_url_el.dataset.nextUrl); // Обновить URL с текущей страницей
    next_url_el.addEventListener("ajax:success", (event) => {
      loading = false
      checkArticleUrl() // обсервер на отслеживание событий для подгружаемых статей (для смены урлов при прокрутке)
      checkArticleNeedLoad() // обсервер на необходимость подгрузки следующей статьи

      // -- подгрузка рекламы --
      if (true) {
        try {
          // над статьей
          window.yaContextCb.push(()=>{
            Ya.Context.AdvManager.render({
              "blockId": "R-A-1985282-22",
              "renderTo": "yandex_rtb_R-A-1985282-22-" + next_url_el.dataset.id
            })
          })
          // под статьей
          window.yaContextCb.push(()=>{
            Ya.Context.AdvManager.render({
              "blockId": "R-A-1985282-23",
              "renderTo": "yandex_rtb_R-A-1985282-23-" + next_url_el.dataset.id
            })
          })
        } catch (e) {
        }
      }
    });
  }
  return loading;
}

// следим за сменой урлов при загрузке соответствующих блоков: .article-outer-container, .article-inner-end-container
// - вызывается при каждой подгрузке статьи (поэтому сначала надо снять старые обсерверы и на все статьи повесить новые)
function checkArticleUrl() {
  let articleObserver = new IntersectionObserver(
    handleArticlesIntersection,
    {
      root: null, // Используем viewport как корневой элемент
      threshold: 0.1, // Задаем порог видимости (1 - 100%, 0.05 - 5%)
    }
  );
  document.querySelectorAll(".article-outer-container, .article-inner-end-container").forEach(el => {
    articleObserver.unobserve(el)
    articleObserver.observe(el)
  });
}
function handleArticlesIntersection(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // console.log(entry.target.dataset.url)
      history.pushState(null, null, entry.target.dataset.url); // Обновить URL с текущей страницей
    } else {
      // console.log('else: ' + entry.target.dataset.url)
    }
  });
}


// следим за нахождением в зоне видимости предпоследней статьи
// - при попадании конца предпоследней статьи в зону видимости подгружаем новую статью
function checkArticleNeedLoad() {
  let articleNeedLoadObserver = new IntersectionObserver(
    handleLastArticleIntersection, { root: null, threshold: 1 }
  );
  elements = document.querySelectorAll(".article-inner-end-container");
  elements.forEach(el => {
    articleNeedLoadObserver.unobserve(el)
  });
  // навешиваем обсервер только на попадание конца предпоследней статьи в зону видимости
  articleNeedLoadObserver.observe(elements[elements.length - 2])
}
function handleLastArticleIntersection(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      clickNextArticle(false);
    }
  });
}
// ------------- /Бесконечный скролл на странице статьи со сменой урла -------------